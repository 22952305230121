
.form-divider {
  display: block;
  height: 1px;
  margin: 16px 0;
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
  overflow: hidden;
}
.form-divider::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.iconLine {
  margin-left: 6px;
  font-size: 24px;
}

